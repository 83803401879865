.resend-text {
  color: red;
  margin-left: 15px !important;
  margin-top: 10px !important;
}

.edit-number {
  text-decoration: underline !important;
  cursor: pointer !important;
}
.dashboard-header {
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 44px !important;
  font-family: OpenSans-SemiBold !important;
}
.listData:hover .heading-text {
  text-decoration: underline !important;
}
.curserEffect {
  cursor: pointer !important;
}
/* .curserEffect:hover .list-image {
  cursor: pointer !important;
} */

.footer-main {
  background-color: rgb(237, 237, 237) !important;
}
.footer-text {
  text-decoration: none !important;
  color: black;
}
.footer-heading {
  font-weight: 700 !important;
  font-size: medium !important;
  color: black !important;
  text-transform: capitalize !important;
}
.footer-text {
  font-weight: 400 !important;
  font-size: smaller !important;
  color: black !important;
  text-transform: capitalize !important;
}
