/* src/assets/fonts/fonts.css */
@font-face {
  font-family: 'Lato-Regular';
  src: url('../assets/font/Lato-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('../assets/font/Sans/static/OpenSans-Regular.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../assets/font/Sans/static/OpenSans-SemiBold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../assets/font/Sans/static/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
/* You can also define font-weight and font-style if you have multiple font files. */
