body {
  margin: 0;
  font-family: OpenSans-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-os-semi-bold {
  font-family: OpenSans-SemiBold !important;
}
.os-bold {
  font-family: OpenSans-Bold !important;
}
.font-OpenSans-Regular {
  font-family: OpenSans-Regular !important;
}
.black-captital {
  color: black !important;
  text-transform: capitalize !important;
}
.white-captital {
  color: white !important;
  text-transform: capitalize !important;
}
.gray-font {
  color: rgb(117, 117, 117) !important;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}
.pointer-portion:hover {
  cursor: pointer;
  transform: scale(0.99) !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.image-pointer:hover {
  transform: scale(1.02) !important;
  cursor: pointer;
}
.image-pointer {
  width: 100%;
  height: 'auto';
  transition: transform 0.2s ease;
}
.lite-gray-border {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
}
.blue-text {
  color: rgb(110, 66, 229);
  font-weight: 600 !important;
  font-family: OpenSans-SemiBold !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
}
