.home-image {
  object-fit: contain;
  width: 100%;
}
.carousel-image:hover {
  transform: scale(1.05) !important;
  cursor: pointer;
}
.carousel-image {
  width: 100%;
  height: 'auto';
  transition: transform 0.2s ease;
}
.parent-img {
  overflow: hidden;
}
.lite-gray-border:hover {
  border: 2px solid #899499;
  border-radius: 10px;
  cursor: pointer;
  transform: scale(0.99) !important;
}
.lite-gray-border {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
}
