.search-box .MuiOutlinedInput-root {
  border-radius: 8px; /* Customize border radius */
}

.search-box .MuiOutlinedInput-root:hover fieldset {
  border-color: black;
}

.search-box .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: black;
}
.search-box .option-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
}

.search-box .option-icon {
  font-size: 1.5rem; /* Adjust icon size as needed */
}
.search-box .option-item {
  border: 2px solid black !important;
}
.search-box .MuiAutocomplete-popper .MuiAutocomplete-paper {
  border-radius: 8px; /* Customize border radius for the dropdown */
}

.search-box .MuiAutocomplete-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px; /* Optional: Adds border radius to each option */
}

.option-item {
  display: inline-block;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-radius: 4px; /* Adds border radius to each option */
  border: 1px solid #ccc; /* Border around each option */
  margin: 2px 0; /* Margin between options */
  background-color: #fff; /* Background color for each option */
  width: auto;
  box-sizing: border-box;
}
.option-item:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

.search-box .option-item .MuiSvgIcon-root {
  margin-right: 8px; /* Space between icon and text */
  font-size: 1.5rem; /* Adjust icon size as needed */
}

.search-box .option-item .option-label {
  flex: 1; /* Ensures the label takes up remaining space */
}

.search-box .MuiAutocomplete-option[aria-selected='true'] {
  background-color: #e0e0e0; /* Customize background color of the selected option */
  font-weight: bold; /* Optional: Make selected option text bold */
}
.option-item .option-label {
  flex: 1; /* Ensures the label takes up remaining space */
}
