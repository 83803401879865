.service-amount {
  color: green;
  font-weight: 400;

  font-size: 12px;
}
.fw-7 {
  font-weight: 700 !important;
}
.font-line-through {
  text-decoration-line: line-through;
}
.text-dotted-underline {
  text-emphasis-style: open;
  text-emphasis-position: under left; /* Add both vertical and horizontal values */

  text-emphasis: circle black;
}
/* styles.css */
.list-item-with-dot {
  position: relative;
  padding-left: 20px; /* Adjust as needed to make room for the dot */
}

.list-item-with-dot::before {
  content: '•'; /* The dot character */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: black;
}
.image-container {
  position: relative;
}

.product-image {
  width: 180px !important;
  /* height: auto; */
  /* height: 75%; */
  height: 180px !important;
}
.bathroomImage {
  bottom: 30% !important;
}
.acImage {
  bottom: 10% !important;
}
.airPurifierImage {
  bottom: 20% !important;
}
.bathroomImageAdd {
  bottom: 99% !important;
}
.acImageAdd {
  bottom: 40% !important;
}
.airPurifierImageAdd {
  bottom: 40% !important;
}
.image-button {
  position: absolute;
  left: 50%;
  /* bottom: 20% !important; */
  transform: translateX(-50%);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.image-button-add {
  position: absolute;
  left: 50%;
  /* bottom: 99% !important; */
  transform: translateX(-50%);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.product-button-container {
  position: absolute !important;
  bottom: 30% !important;
  left: 50%;
  transform: translateX(-50%) !important;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
html {
  scroll-behavior: smooth;
}
/* ProductList.css */

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding-bottom: 10px;
}

.scrollable-content {
  border: 2px solid rgba(245, 245, 245, 1);
  padding: 10px;
  border-radius: 10px;
  height: 400px; /* Set a fixed height for the scrollable area */
  overflow: auto; /* Enable scrolling */
}

.list-image {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  height: 100px;
}

/* styles.css */
.fixed-content {
  position: fixed;
  top: 200px;
  left: 0px;
}

.paper {
  padding: 16px;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
}
/* Container with single scroll */
.scroll-container {
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Flex container for columns */
.columns {
  display: flex;
  width: 300%; /* To accommodate three columns side by side */
}

/* Style for individual columns */
.column {
  width: 33.33%; /* Each column takes up one-third of the width */
}

.column-1,
.column-3 {
  position: relative;
}

.column-1 {
  height: 100vh; /* Full viewport height to see the scroll effect */
}

.column-2 {
  height: 100vh; /* Full viewport height */
}

.column-3 {
  height: 100vh; /* Full viewport height */
}

.row {
  height: 20vh; /* Each row height for demonstration */
}

/* Additional styling for visualization */
.column {
  border: 1px solid black;
  box-sizing: border-box;
}

.row {
  border-bottom: 1px solid gray;
}
@media (min-width: 900px) {
  .fixed-section {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 100px; /* Adjust as necessary */
    height: fit-content;
    overflow: hidden;
  }

  .scrollable-section {
    overflow-y: auto;
    flex: 1;
  }

  .fixed-content {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Adjust as necessary */
  }
}
.gray-button {
  border: 2px solid #d3d3d3 !important;
  font-weight: 500 !important;
  color: gray !important;
  text-transform: capitalize !important;
}
.black-button {
  border: 2px solid black !important;
  font-weight: 300;
  color: #000 !important;
  text-transform: capitalize !important;
}
.gray-border {
  border: 2px solid #d3d3d3;
  border-radius: 10px;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-space-between {
  display: flex;
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.p-10 {
  padding: 20px 0px 20px 0px;
}
.font-os-semi-bold {
  font-family: OpenSans-SemiBold !important;
}
.blue-color {
  color: rgb(110, 66, 229) !important;
}
.image-container {
  position: relative;
  width: 400px;
}

.image {
  width: 100%;
  display: block;
}

/* .image-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
} */

.image-button:hover {
  background-color: #0056b3;
}
.blue-button {
  color: #0056b3 !important;
}
